import { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { Grid, Box } from "@mui/material"

import { Extra } from "../components/extra"
import { Layouts } from "../../components/layouts/layouts"
import { CustomBreadcrumbs } from "../components/breadcrumbs"
import { PopularArticles } from "../components/popularArticles"
import { BlogItem } from "../components/blogitem"
import ContactAuthorSection from "../components/ContactAuthorSection"

const blogSeoData: BlogData[] = [
	{
		title: 'Eliminación de Malware',
		description: [
			'Pocas cosas son más desesperantes para un propietario de un sitio que el mismo haya sido hackeado o infectado con malware. Esto significa que alguien ha secuestrado su sitio web y a Ud. le cuesta tiempo, dinero y clientes.',
			'<br>Podemos eliminar el malware para lograr que su sitio funcione correctamente de nuevo, y le ayudamos  a <strong>asegurar </strong>que el intruso no pueda recuperar el accesso a su sitio. Podemos desinfectar su sitio y asegurar que su computadora también esté segura. Eliminamos el malware a un precio razonable en función del tamaño y complejidad del sitio web.-',
			'<br>Cuando su sitio se ve afectado por malware y ha sido hackeado ahuyenta a los visitantes, potenciales clientes y daña la reputación de su empresa. Se cobra $50.00 por una inspección inicial y diagnóstico. El costo final dependerá de la naturaleza y el alcance de la infección.',
			'<br>Si desea realizar una comprobación rápida de malware en su sitio o cualquier otro sitio web, puede utilizar la herramienta de navegación segura de Google. Sólo tienes que escribir la url del sitio:',
			'<br>La herramienta le dirá si Google ha detectado malware en el sitio web, el número de <strong>páginas web </strong>que se han probado en los últimos 90 días, y el número de redes (servidores) donde se aloja el sitio.'
		]
	}
]

const EliminacionDeMalware = () => {
	const [text, setText] = useState('http://');
	const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        alert(`${text}`);
    };
	useEffect(() => {
		document.title = 'Eliminación de Malware - Consultoria SEO';
	  }, []);
	return (
		<Layouts>
			<SeoWrapper>
				<CustomBreadcrumbs />

				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<Extra />
					</Grid>

					<Grid item xs={12} md={6}>
						<Box display="flex" flexDirection="column" gap={2}>
							{blogSeoData.map((item, index) => (
								<BlogItem key={index} {...item} />
							))}
							<form  
                                onSubmit={handleSubmit} 
                                style={{
                                    padding: '5px',
                                    margin: '5px'
                                }}
                            >
                                <input 
                                    type="search"
                                    maxLength={255} 
                                    name="URL" 
                                    value={text} 
                                    onChange={(e) => setText(e.target.value)} 
                                    id='search-box' 
                                    style={{
										width: '80%',
                                        padding: '1px 3px 3px 3px',
                                        display: 'block',
                                        margin: '10px 0'
                                    }}
                                />
                                <input 
                                    type="submit" 
                                    id='search-btn' 
                                    name="comprobar" 
                                    value="Examinar esta url" 
                                    style={{
                                        padding: '5px',
                                        margin: '0 10px 0 0',
                                        display: 'block'
                                    }}
                                />
                            </form>

							<ContactAuthorSection />
						</Box>
					</Grid>

					<Grid item xs={12} md={3}>
						<PopularArticles />
					</Grid>
				</Grid>
			</SeoWrapper>
		</Layouts>
	)
}

const SeoWrapper = styled(Box)({
	gap: '5px',
	paddingTop: '10px',
	display: 'flex',
	flexDirection: 'column',
})

export { EliminacionDeMalware }